import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class News extends React.Component {
	render() {
		const siteTitle = 'News'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Newsletter</h3>
								<div className="newsletter subscribe">
									<p>
										To keep up to date with the latest in{' '}
										<strong>WSC Group</strong> news, please subscribe to our
										enewsletter by entering your details in the fields provided.
									</p>
									<div id="mc_embed_signup">
										<form
											className="validate"
											id="form-validation-test2"
											name="WSC | Free Download Tips"
											action="//wscgroup.us12.list-manage.com/subscribe/post?u=c6ff676dbd6024a250b229efe&amp;id=9484e38a6c"
											method="post"
											netlify="netlify"
											netlify-honeypot="bot-field"
											novalidate=""
											target="_blank"
											rel="noopener noreferrer"
										>
											<div id="mc_embed_signup_scroll">
												<div className="indicates-required">
													*indicates required
												</div>
												<div className="row">
													<div className="col-12 px-0">
														<label htmlFor="firstName">First Name</label>
														<div>
															<input
																type="text"
																name="First Name"
																id="firstName"
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6 col-sm-6 col-xs-6 pl-0">
														<label htmlFor="lastName">Last Name</label>
														<div>
															<input
																type="text"
																name="Last Name"
																id="lastName"
															/>
														</div>
													</div>
													<div className="col-md-6 col-sm-6 col-xs-6 pr-0">
														<label htmlFor="company">Company</label>
														<div>
															<input type="text" name="Company" id="company" />
														</div>
													</div>
													<div className="col-12 px-0 mb-4">
														<label htmlFor="emailAddress">Email Address</label>
														<div>
															<input
																type="text"
																name="Email Address"
																id="emailAddress"
															/>
														</div>
													</div>
												</div>
												<div style={{ position: `absolute`, left: `-5000px` }}>
													<input
														tabindex="-1"
														name="b_c6ff676dbd6024a250b229efe_9484e38a6c"
														type="text"
														value=""
													/>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="webriq-recaptcha" />
													</div>
												</div>
												<div className="clear">
													<input
														className="button"
														id="mc-embedded-subscribe"
														name="subscribe"
														type="submit"
														value="Subscribe"
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default News

export const NewsPageQuery = graphql`
	query NewsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
